import React, { useEffect, useState } from 'react';
// Require Editor CSS files.
import config from './config';
import { store } from 'react-notifications-component';
import { EditorState, convertToRaw, ContentState, convertFromRaw, onCopy, onPaste } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import './HtmlEditor.scss';

export default function HtmlEditor({ defaultValue, onChange }) {
  const [editorValue, setEditorValue ]= useState();
  function onEditorStateChange(state) {
    setEditorValue(state);
    let html  = draftToHtml(convertToRaw(state?.getCurrentContent()));
    onChange(html);
  };

  useEffect(() => {
    if (defaultValue) {
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorValue(editorState);
    } else {
      setEditorValue(EditorState.createEmpty());
    }
  },[defaultValue]);

  const copyToClipboard = () => {
    const rawContent = convertToRaw(editorValue.getCurrentContent());
    const stringifiedContent = JSON.stringify(rawContent);
    navigator.clipboard.writeText(stringifiedContent).then(() => {
      if (onCopy) onCopy();
      store.addNotification({
        message: "Contenu copié",
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const pasteFromClipboard = async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText();
      if (clipboardContent) {
        const rawContent = JSON.parse(clipboardContent);
        const contentState = convertFromRaw(rawContent);
        setEditorValue(EditorState.createWithContent(contentState));
        if (onPaste) onPaste();
      }
    } catch (err) {
      console.error('Failed to paste: ', err);
    }
  };

  return (
    <div>
      <Editor
        editorState={editorValue}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={config}
      />
      <div className="copy">
      <button onClick={copyToClipboard}>Copier</button>
      {navigator?.clipboard && navigator?.clipboard?.readText() && <button onClick={pasteFromClipboard}>Coller</button>}
      </div>
    </div>
  )
}
