import React , { useEffect , useCallback , useState } from 'react';
import { API_URL } from '../../../actions/index';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

import { translateDate } from "../../../utils/utils";

import { useGlobalContext } from '../../context/GlobalContext';
import { getOfferAction, putProductAction } from '../../../actions/offersActions';

// import Input from "../../Partials/form_fields/Input";
import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

// import Animation from "./Animations_Partials/Animation";
import Loader from "../../Partials/Loader";
import { offersStatusOptions } from '../../../constants/constants';

export default function OfferView({ setActivePage, match }) {

  const [ context , dispatch ] = useGlobalContext();
  const [ imgError , setImgError ] = useState(false);

  const offer = context.offers.offer;

  var color = "#FF8900";
  if(offer?.status === "valid") {
    color = "#32A860";
  }
  if(offer?.status === "correction") {
    color = "#F4D300";
  }
  if(offer?.status === "refused") {
    color = "#FF0009";
  }

  const getOffer = useCallback(() => {
    getOfferAction( dispatch, match.params.id);
    setActivePage("offers");
    window.scrollTo(0,0);
  }, [dispatch, match.params.id, setActivePage]);

  async function handleValidation() {
    putProductAction(dispatch, offer._id, { status: 'valid' })
  }

  useEffect(() => {
    getOffer();
  }, [getOffer]);

  function renderOffer() {
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>
            <span className="hide-mobile text" style={{ marginLeft: 0 }}>
              {offer.ref}
            </span>
          </h1>
        </header>

        <main className="page-content">

          <div className="row col-2">
            <div className="col-70">
            <Link to={"/offers"} className="btn-edit mb-10">
              <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
            </Link>

            <Link to={`/offer-edit/${offer._id}`} className="btn-edit mb-10" style={{  marginLeft: 10}}>
              <button className="btn-edit" style={{  marginBottom: 10, width : 100}}><Translate id="btn.edit"/></button>
            </Link>

            {offer.status !== "valid" &&
              <button
                type="button"
                className="btn-edit"
                style={{ backgroundColor: "#00BA38", marginBottom: 10, width : 120 , float: "right"}}
                onClick={() => handleValidation()}
              >
                Valider l'offre
              </button>
              }

              <div className="card">
                <div className="product-img">
                {!imgError && !offer?.image?.path && <img
                  src={`${API_URL}/static/products/${offer.gencod}.jpg`}
                  onError={() => setImgError(true)}
                  alt="product"/>}

              {offer?.image?.path && <img
                src={`${API_URL}/files/${offer.image.path.replace('upload/', '')}`}
                alt="product"/>}
              </div>

                <div className="information-container offer">
                  <p className="p-col-3 offer">
                    <span className="bold">Avantage salon :</span><br/>
                    {offer?.discount && <span className="number bold text-orange">{offer.discount} %</span>}
                    {offer?.punch && <span><span className="number bold text-orange">{offer.punch }</span><br/><span className="bold">Coup de point</span></span>}
                    {offer?.gift && <span><span className="number bold text-orange">{offer.gift }</span><br/><span className="bold">Cadeau</span></span>}
                  </p>

                  {offer?.price &&
                    <p className="p-col-3 offer">
                      <span className="bold">Prix net HT :</span><br/>
                      <span className="number bold text-primary">{offer?.price?.toFixed(2)} €</span>
                    </p>
                  }
                  {offersStatusOptions.find((opt) => opt.value === offer?.status)?.label &&
                    <p className="p-col-3 offer">
                      <span className="bold">Status de l'offre :</span><br/>
                      <span className="number bold" style={{ color }}>{offersStatusOptions.find((opt) => opt.value === offer.status)?.label}</span>
                    </p>
                  }

                </div>

                <div className="information-container offer border">

                  <p className="p-col-3 offer">
                    <span className="bold">Fournisseur :</span><br/>
                    {offer?.company?.name}
                  </p>


                  <p className="p-col-3 offer">
                    <span className="bold">Désignation produit :</span><br/>
                    {offer.description}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Marque :</span><br/>
                    {offer.brand}
                  </p>

                </div>

                <div className="information-container offer border">
      
                  <p className="p-col-3 offer">
                    <span className="bold">Livraison :</span><br/>
                    {offer?.delivery || '---'}
                  </p>
            

                  <p className="p-col-3 offer">
                    <span className="bold">Gencod :</span><br/>
                    {offer.gencod}
                  </p>
          
                  <p className="p-col-3 offer">
                    <span className="bold">Colisage :</span><br/>
                    {offer.packing || '---'}
                  </p>

                </div>

                {offer?.box &&
                  <div className="information-container offer border">
                    <p className="p-col-1 offer">
                      <span className="bold">Détail box :</span><br/>
                      {offer.box || '---'}
                    </p>
                  </div>
                }

                <div className="information-container offer border">
                  {offer.pricePABrut &&
                    <p className="p-col-3 offer">
                      <span className="bold">PA BRUT :</span><br/>
                      {offer.pricePABrut?.toFixed(2)} €
                    </p>
                  }

                  <p className="p-col-3 offer">
                    <span className="bold">PA permanent :</span><br/>
                    {offer.pricePA?.toFixed(2) || '---'} €
                  </p>
                
                  <p className="p-col-3 offer">
                    <span className="bold">TVA : </span><br/>
                    {offer.tva || '---'}
                  </p>
              
                </div>


                <div className="information-container offer border">

                  <p className="p-col-3 offer">
                    <span className="bold">DEEE</span><br/>
                    {offer.deee || '---'}
                  </p>
      
                  <p className="p-col-3 offer">
                    <span className="bold">Autres taxes : </span><br/>
                    {offer.tax || '---'}
                  </p>
              
                </div>


                {(offer?.discountGalec || offer?.discountPromo) &&
                  <div className="information-container offer border">
                    <p className="p-col-3 offer">
                      <span className="bold">Détail remises permanent :</span><br/>
                      {offer.discountGalec || '---'}
                    </p>
                    <p className="p-col-3 offer">
                      <span className="bold">Remise Promo :</span><br/>
                      {offer.discountPromo || '---'}
                    </p>
                  </div>
                }

                {offer.department &&
                  <div className="information-container offer border">
                    <p className="p-col-3 offer">
                      <span className="bold">Département de l'accord :</span><br/>
                      {offer.department || '---'}
                    </p>
                  </div>  
                }

                {(offer?.product || offer?.code || offer?.fileNumber) &&
                  <div className="information-container offer border">
                    <p className="p-col-3 offer">
                      <span className="bold">Typo</span><br/>
                      {offer.product || '---'}
                    </p>
                    <p className="p-col-3 offer">
                      <span className="bold">Code article :</span><br/>
                      {offer.code || '---'}
                    </p>
                    <p className="p-col-3 offer">
                      <span className="bold">Numéro de dossier :</span><br/>
                      {offer.fileNumber || '---'}
                    </p>
                  </div>
                }

                <div className="information-container offer border">
                  {offer.codelec &&
                    <p className="p-col-3 offer">
                      <span className="bold">Codelec :</span><br/>
                      {offer.codelec || '---'}
                    </p>
                  }
                  {offer.deliveryDate &&
                    <p className="p-col-3 offer">
                      <span className="bold">Semaine de livraison :</span><br/>
                      {offer.deliveryDate || '---'}
                    </p>
                  }
                </div>
                <span style={{ fontSize: 12 , opacity: 0.58 }} >
                 {offer?.createdAt && <>Créée le {translateDate(offer.createdAt, 'fr')} {offer?.user?.email && <>par {offer?.user?.email}</>}<br/></>}
                  Modifiée le {translateDate(offer.updatedAt, 'fr')}
                </span>
              
              </div>

            </div>
          </div>

        </main>
      </>
    );
  };

  return (
    <div className="page-container">
      {offer?._id ? 
        renderOffer()
      :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  );
};