import React , {
  useEffect,
  useState,
  // useEffect
} from "react";
import { Translate } from "react-localize-redux";

import { useGlobalContext } from '../context/GlobalContext';

import '../../styles/Nav.scss';
// import LanguageToggle from "./LanguageToggle";
import logo from '../../assets/images/logo-leclerc.svg';
import bars from '../../assets/images/icons/bars.svg';
import close from '../../assets/images/icons/close.svg';
import exhibitionIcon from '../../assets/images/icons/exhibition.svg';
import exhibitionHover from '../../assets/images/icons/exhibition_hover.svg';
import userIcon from '../../assets/images/icons/user.svg';
import userIconHover from '../../assets/images/icons/user_hover.svg';
import provider from '../../assets/images/icons/provider.svg';
import providerHover from '../../assets/images/icons/provider_hover.svg';
import homeIcon from '../../assets/images/icons/home.svg';
import homeIconHover from '../../assets/images/icons/home_hover.svg';
import shops from '../../assets/images/icons/shops.svg';
import shopsHover from '../../assets/images/icons/shops_hover.svg';
import market from '../../assets/images/icons/market.svg';
import marketHover from '../../assets/images/icons/market_hover.svg';
// import anim from '../../assets/images/icons/anim.svg';
// import animHover from '../../assets/images/icons/anim_hover.svg';
// import assembly from '../../assets/images/icons/assembly.svg';
// import assemblyHover from '../../assets/images/icons/assembly_hover.svg';
import offers from '../../assets/images/icons/offers.svg';
import offersHover from '../../assets/images/icons/offers_hover.svg';
import orders from '../../assets/images/icons/orders.svg';
import ordersHover from '../../assets/images/icons/orders_hover.svg';
// import notification from '../../assets/images/icons/notification.svg';
// import notificationHover from '../../assets/images/icons/notification_hover.svg';

import accueil from '../../assets/images/icons/accueil.svg';

import NavLink from '../Partials/NavLink';
import SelectExhibition from "./SelectExhibition/SelectExhibition";
import { Link, useLocation } from "react-router-dom";

export default function Nav({
  activePage,
  setActivePage,
  logout,
  history,
}) {

  const context = useGlobalContext()[0];
  const location = useLocation();
  const { user } = context.auth; 
  const daysOptions = context.providers?.lists?.days;

  const [ showNav , setShowNav ] = useState(false);

  const [ showReception ,  setShowReception ] = useState(false);

  useEffect(() => {
    if(activePage !== "login" && context.auth.error === 401) {
      logout("401");
    }
    // eslint-disable-next-line 
  },[context.auth.error])

  useEffect(() => {
    if(activePage === "reception") {
      setShowReception(true);
    } else {
      setShowReception(false);
    }
    // eslint-disable-next-line
  }, [activePage]);

  const navLinks = [
    {
      name: "home",
      icon: homeIcon,
      iconHover: homeIconHover,
      url: "/",
      label: <Translate id="nav.home"/>
    },
    {
      name: "providers",
      icon: provider,
      iconHover: providerHover,
      url: "/providers",
      label: <Translate id="nav.providers"/>
    },
    {
      name: "shops",
      icon: shops,
      iconHover: shopsHover,
      url: "/shops",
      label: <Translate id="nav.shops"/>
    },
    {
      name: "stands",
      icon: market,
      iconHover: marketHover,
      url: "/stands",
      label: <Translate id="nav.stands"/>
    },
    // {
    //   name: "animations",
    //   icon: anim,
    //   iconHover: animHover,
    //   url: "/animations",
    //   label: <Translate id="nav.animations"/>
    // },
    // {
    //   name: "assemblies",
    //   icon: assembly,
    //   iconHover: assemblyHover,
    //   url: "/assemblies",
    //   label: <Translate id="nav.assembly"/>
    // },
    {
      name: "offers",
      icon: offers,
      iconHover: offersHover,
      url: "/offers",
      label: <Translate id="nav.offers"/>
    },
    {
      name: "users",
      icon: userIcon,
      iconHover: userIconHover,
      url: "/users",
      label: <Translate id="nav.users"/>
    },
    {
      name: "orders",
      icon: orders,
      iconHover: ordersHover,
      url: "/orders",
      label: <Translate id="nav.orders"/>
    },
    // {
    //   name: "notifications",
    //   icon: notification,
    //   iconHover: notificationHover,
    //   url: "/notifications",
    //   label: <Translate id="nav.notifications"/>
    // },
  ];

  const exhibitionLink = {
    name: "exhibition",
    icon: exhibitionIcon,
    iconHover: exhibitionHover,
    url: "/exhibition",
    label: <Translate id="nav.exhibitions"/>
  }

  function renderProfile() {
    if (context.auth.user && context.auth.user.profile) {
      return (
        <>
          <p>
            <span>{context.auth.user.profile.firstName} {context.auth.user.profile.lastName}</span><br/>
            <span className="role">{context.auth.user.role}</span>
          </p>
          <button className="btn-logout" onClick={() =>logout(null)}><Translate id="nav.logout"/></button>
        </>
      );
    };
  };

  function renderLinks() {
    if (context?.auth?.user?.role && context?.auth?.user?.role ==="Admin") {
      return navLinks
        .map((link, i ) => {
        return (
          <NavLink
            key={i}
            showNav={showNav}
            setShowNav={setShowNav}
            activePage={activePage}
            setShowReception={setShowReception}
            setActivePage={setActivePage}
            link={link} />
        );
      })
    }
  };

  function closeReceptionNav() {
    if(showReception) {
      setShowReception(false);
    }
  }

  function renderReceptionLink() {
    return (
      <li
        onClick={() => setShowReception(true)}
        onMouseOver={() => setShowReception(true)}
        onMouseOut={() => closeReceptionNav()}
        className={`reception ${showReception ? "isActive" : ""}`}>
        <div
          className="btn-nav reception"
          >
          <img src={accueil} alt="accueil"/>
          <span className="nav-label">Accueil</span>
        </div>

        <ul className={showReception || activePage === "reception" ? "isActive" : ""}>
          {daysOptions?.map((day) => (
            <li
              key={day.value}
            >
              <Link
                onClick={() => { setActivePage("reception"); setShowNav(false)}}
                className={location.pathname.includes(day.value) ? "isActive": ""}
                to={`/reception/${day.value}`}>
                <span className="bold">{day.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </li>
    )
  };

  return (
    <>
    <nav className={`main-nav ${showNav? "mobile" : ""}`}>
      <div>
        <div className="user-profile">
          {renderProfile()}
        </div>
        <ul className="links-list">
        <NavLink
          showNav={showNav}
          setShowNav={setShowNav}
          activePage={activePage}
          // setShowReception={setShowReception}
          setActivePage={setActivePage}
          link={exhibitionLink} />
        </ul>
        {user?.exhibitions?.length > 0 &&
          <SelectExhibition history={history}/>
        }
      </div>
      <ul className="links-list">
        {renderLinks()}
        {renderReceptionLink()}
      </ul>
      <div className="logo-container">
        <img src={logo} alt="logo-btlec"/>
        <span className="bold">{process?.env.REACT_APP_NAME}</span>
      </div>
      {/* <LanguageToggle /> */}
    </nav>
    <button className="btn-nav-toggle" onClick={() => setShowNav(!showNav)}><img src={showNav? close : bars} alt="burger-menu"/></button>
    </>
  )
}